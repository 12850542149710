import React from "react";
import Link from "gatsby-link";
//import { normalizePath } from "../../utils/functions";
import FacebookIcon from "../icons/facebook-icon";
// import TwitterIcon from "../icons/twitter-icon";
import InstagramIcon from "../icons/instagram-icon";
import LinkedInIcon from "../icons/linkedin-icon";
import FootEuImage from "../../images/eu-foot-en.svg";
import FootBg from "../../images/newshroom.svg";
import PropTypes from "prop-types";

import NewsletterForm from "../newsletter/newsletter.js";
// import CookieConsent from 'react-cookie-consent'
//import ConsentForm  from '../../components/cookies/ConsentForm'
import defaultSiteLogoUrl from "../../images/logo.svg";
// import BgNews from "../../images/newshroom.jpg";
// import PayPal from "../../images/PayPal.svg";
// import Blik from "../../images/blik.svg";
// import P24 from "../../images/Przelewy24_logo.svg";
// import SetCredit from "../../images/set_credit_card.png";
import CheckoutLink from "../checkout-link";
import ReloadLink from "../../utils/reloadLink.js";

const Footer = ({ data }) => {
  const {
    wp: {
      footer: { socialLinks },
    },
    allWpPost,
    allWpCategory,
    allWpProductCategory,
    footerMenuItems,
  } = data;

  const staticSocialLink = [
    { iconName: "instagram", iconUrl: "https://www.instagram.com/shroom4you" },
    {
      iconName: "linkedin",
      iconUrl: "https://www.linkedin.com/company/shroom4you/",
    },
    { iconName: "facebook", iconUrl: "https://www.facebook.com/shroom4you" },
  ];

  const socialLinksData = socialLinks.length ? socialLinks : staticSocialLink;
  return (
    <>
      <NewsletterForm />
      <div
        id="newsletter2"
        style={{
          position: "relative",
          backgroundImage:
            "url(https://admin.shroom4you.com/wp-content/uploads/2024/01/news2-bg.jpg)",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          minHeight: "400px",
        }}
      >
        <div
          id="newsmodule"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            color: "white",
            borderRadius: "25px",
            padding: "2em",
            backgroundColor: "rgba(0,0,0,.5)",
          }}
        >
          <h2 className="spec-h">Newsletter</h2>
          <p>Sign up and join our club. Get promo codes to use on checkout!</p>
          <br />
          <button onClick={newsEnter} className="btn">
            Sign up&nbsp;
            <span role="img" aria-label="mushroom">
              🍄
            </span>
          </button>
        </div>
      </div>

      <footer
        className="footer"
        style={{
          backgroundColor: "#4C365C",
          backgroundImage: `url(${FootBg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right bottom",
          marginTop: "-10px",
        }}
      >
        <figure
          style={{
            width: "100%",
            textAlign: "center",
            padding: "50px 50px 0px 50px",
          }}
        >
          <Link to="/">
            <img
              className="site-brand__logo mush"
              src={defaultSiteLogoUrl}
              width="128"
              height="auto"
              alt="header logo"
              style={{ filter: "invert(1)" }}
            />
          </Link>
        </figure>
        <div className="container">
          <div className="footer__top">
            {/*Top section
					{ sidebarOne ? (
						<div
							dangerouslySetInnerHTML={ { __html: sanitize( sidebarOne ) } }
							className="footer__sidebar-one footer-widget"
						/>
					) : null }
					{ sidebarTwo ? (
						<div
							dangerouslySetInnerHTML={ { __html: sanitize( sidebarTwo ) } }
							className="footer__sidebar-two footer-widget"
						/>
					) : null }
					*/}
            {footerMenuItems.edges.length ? (
              <div className="footer-menu-items footer-widget">
                <h2>Shop</h2>
                <ul>
                  {footerMenuItems.edges.map((menu) => (
                    <li key={menu.node.databaseId + "_footer"}>
                      <Link
                        className="header-nav__menu-link"
                        to={menu.node.url}
                      >
                        {menu.node.label}
                      </Link>
                    </li>
                  ))}
                  <li key={"checkout"}>
                    <CheckoutLink />
                  </li>
                  <li key={"wishlist"}>
                    <Link className="header-nav__menu-link" to="/wishlist/">
                      Wishlist
                    </Link>
                  </li>
                  <li key={"Shroomscriptions"}>
                    <Link
                      className="header-nav__menu-link"
                      to="/shroomscriptions/"
                    >
                      Shroomscriptions
                    </Link>
                  </li>
                </ul>
                <br />
                <h2>Categories</h2>
                <ul>
                  {allWpProductCategory.edges.map((post) => {
                    return (
                      <>
                        <li key={post.node.name} className="navbar-item">
                          <Link to={post.node.uri}>{post.node.name}</Link>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
            ) : (
              ""
            )}

            <div className="footer-menu-items footer-widget">
              <h2>About us</h2>
              <ul>
                <li key={"about us"}>
                  <Link to="/our-story/">Our story</Link>
                </li>
                <li key={"find us"}>
                  <ReloadLink to="/where-to-buy/">Where to buy?</ReloadLink>
                </li>
                <li key={"faq"}>
                  <Link to="/faq/">FAQ</Link>
                </li>
                <li key={"contact"}>
                  <Link to="/contact/">Contact us</Link>
                </li>
                <li key={"blog"}>
                  <a
                    href="https://shroom4you.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blog
                  </a>
                </li>
              </ul>
              <br />
              <h2>News</h2>
              <ul>
                {allWpCategory.nodes.map((post) => {
                  return (
                    <>
                      <li key={post.name} className="navbar-item">
                        <a
                          href={"https://shroom4you.com" + post.uri}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {post.name}
                        </a>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>

            <div className="footer-menu-items footer-widget">
              <h2>Blog</h2>
              <ul>
                {allWpPost.edges.map((post) => {
                  return (
                    <>
                      <li key={post.node.title} className="navbar-item">
                        <a
                          href={"https://shroom4you.com" + post.node.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {post.node.title}
                        </a>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>

            <div className="footer-menu-items footer-widget">
              <h2>Shroom Sp. z o.o.</h2>
              <ul>
                <li key={"KRS"}>KRS: 0000867640</li>
                <li key={"NIP"}>VATEU: PL7162830959</li>
                <li key={"REGON"}>REGON: 387403324</li>
                <li key={"Share capital"}>Share capital: 5000 PLN</li>
              </ul>
              <br />
              <h2>More</h2>
              <button
                key={"Newsletter"}
                onClick={newsEnter}
                className="btn btn-outline-primary"
                style={{ color: "white" }}
              >
                Newsletter&nbsp;
                <span role="img" aria-label="mushroom">
                  🍄
                </span>
              </button>
            </div>
          </div>
          {/*<small className="text-white">Payments supported:</small>*/}

          {/*<div id="payment_images">
				<img src={SetCredit} alt="Credit card payments" width="auto" style={{borderRadius:'5px',maxHeight:'25px',marginLeft:'.8em',marginRight:'25px'}} />
				<img src={P24} alt="P24 payments" width="auto" style={{maxHeight:'25px',marginRight:'25px'}} />
				<img src={Blik} alt="BLIK payments" width="auto" style={{maxHeight:'25px',marginRight:'25px'}} />
				<img src={PayPal} alt="PayPal payments" width="auto" style={{maxHeight:'25px',marginRight:'25px'}} />
				<br />
			</div>*/}

          {/*	Bottom section*/}
          <div className="footer__bottom">
            <Link id="mob-s" to="/">
              Shroom<sup>©</sup>&nbsp;
              {` ${new Date().getFullYear()}`}
            </Link>
            <div className="copyright-text">
              <Link id="desk-s" to="/">
                Shroom<sup>©</sup>&nbsp;
                {` ${new Date().getFullYear()}`}&nbsp;&nbsp;| &nbsp;
              </Link>
              <Link to="/store-regulations/">Store regulations</Link>&nbsp;|
              &nbsp;<Link to="/privacy-policy/">Privacy policy</Link>
              &nbsp;| &nbsp;
              <Link to="/refunds-policy/">Refunds & Complaints policy</Link>
            </div>
            {socialLinksData.length ? (
              <ul className="social-links">
                {socialLinksData.map((socialLink) => (
                  <li key={socialLink.iconName}>
                    <a
                      href={socialLink.iconUrl}
                      aria-label={socialLink.iconName}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {"instagram" === socialLink.iconName ? (
                        <InstagramIcon />
                      ) : null}
                      {"linkedin" === socialLink.iconName ? (
                        <LinkedInIcon />
                      ) : null}
                      {"facebook" === socialLink.iconName ? (
                        <FacebookIcon />
                      ) : null}
                    </a>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      </footer>
      <p
        style={{
          textAlign: "center",
          backgroundColor: "white",
          padding: "20px 0px",
        }}
      >
        <img
          src={FootEuImage}
          id="foot-logo"
          alt="logo"
          width="auto"
          style={{ maxWidth: "400px", width: "75%", minWidth: "200px" }}
        />
      </p>

      {/*
			Consent mode v2 IMPLEMENTATION OF GDPR COOKIE NOTICE 
		<ConsentForm />
		*/}
    </>
  );
};

Footer.propTypes = {
  copyrightText: PropTypes.string,
};

Footer.defaultProps = {
  copyrightText: `Shroom ${new Date().getFullYear()}`,
};

const newsEnter = () => {
  console.log("enter modal");
  document.getElementById("newsletter").style.display = "block";
  document.getElementById("newsletter").style.transition = "opacity 0.5s";

  setTimeout(function () {
    document.getElementById("newsletter").style.opacity = "1";
  }, 500);
};
/**
 *  Exporting Just the footer as well without static query for storybook,
 *  as static query does not work in storybook
 */
export { Footer };
