import React, { useContext } from "react";
import "./style.scss";
import { AppContext } from "../../context/AppContext";
import CheckoutButton from "../../checkout-btn2";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { isEmpty } from "lodash";
import { Link } from "gatsby";

const CartDropDown = ({ isDropdownOpen }) => {
  const [cart] = useContext(AppContext);

  if (null === cart || !Object.keys(cart).length) {
    return null;
  }

  const productsCount = cart.totalProductsCount ? cart.totalProductsCount : "";
  const totalPriceEu = cart.totalEuWithShipping ? cart.totalEuWithShipping : "";

  const productImagePlaceholder = "https://via.placeholder.com/70";

  return (
    <div className={`shopping-cart ${isDropdownOpen ? "is-open" : ""}`}>
      <div className="shopping-cart-header">
        <div className="cart-icon-wrp">
          <Link to="/cart/">
            {/* eslint-disable */}
            <span className="cart-icon" role="img">
              <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                style={{
                  width: "30px",
                  height: "18pt",
                  transform: "scale(1.25)",
                  paddingTop: ".5px",
                }}
              >
                <g>
                  <g>
                    <path
                      className="st0"
                      d="M10.2,19.9c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.8-0.5,1.2-0.5
									c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2C11.1,19.7,10.6,19.9,10.2,19.9z M10.2,17.1
									c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C11.2,17.6,10.7,17.1,10.2,17.1z"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M16.1,19.9c-0.5,0-0.9-0.2-1.2-0.5c-0.3-0.3-0.5-0.8-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.8-0.5,1.2-0.5
									c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2C17,19.7,16.6,19.9,16.1,19.9z M16.1,17.1
									c-0.6,0-1.1,0.5-1.1,1.1c0,0.6,0.5,1.1,1.1,1.1c0.6,0,1.1-0.5,1.1-1.1C17.2,17.6,16.7,17.1,16.1,17.1z"
                    />
                  </g>
                  <g>
                    <path
                      className="st0"
                      d="M16.9,15.4c-0.3,0-0.7,0-0.9,0c-0.7,0-6.2,0-7.2,0l0,0c-0.1,0-0.5,0-0.9-0.2c-0.1-0.1-0.4-0.2-0.6-0.6
									c0-0.1-0.1-0.2-0.4-1c-0.2-0.6-0.4-1.3-0.6-2C6.1,10.4,5.5,8.2,5,5.8c0,0,0-0.1,0-0.1l0,0C4.7,4.3,4.4,3.6,4.4,3.6l0,0l0,0
									c0,0-0.1-0.2-0.1-0.3c-0.3-0.5-0.8-0.8-1-0.8c-0.9,0-1.7,0-2.2,0c-0.1,0-0.2,0-0.3,0l0-0.7c0.1,0,0.2,0,0.3,0c0.4,0,1.3,0,2.2,0
									c0.3,0,0.5,0.1,0.8,0.3c0.2,0.1,0.5,0.4,0.8,0.8C4.9,3.1,5,3.3,5,3.4c0,0.1,0.3,0.7,0.6,2H8c3.4,0,6.6,0,8.8,0c1.6,0,2.7,0,2.9,0
									l0,0c0.2,0,0.5,0,0.7,0.2C20.7,5.7,20.9,6,21,6.4l0,0c0,0.2,0,0.4-0.1,0.6l0,0c-0.1,0.2-0.2,0.7-0.3,1.4c-0.2,1-0.5,2.3-0.8,3.5
									c-0.2,0.7-0.3,1.2-0.5,1.6c-0.2,0.5-0.4,0.9-0.6,1.2l0,0c-0.1,0.1-0.4,0.5-0.9,0.6C17.8,15.3,17.7,15.4,16.9,15.4
									C17,15.4,16.9,15.4,16.9,15.4z M16.1,14.6c0.6,0,1.4,0,1.6,0c0.3-0.1,0.4-0.3,0.5-0.4l0,0c0.2-0.2,0.5-0.9,1-2.5
									c0.3-1.2,1.1-4.7,1.1-4.9l0,0c0,0,0-0.1,0-0.3c0-0.2-0.1-0.3-0.3-0.4C19.9,6,19.8,6,19.8,6l0,0c-0.5,0-4.8,0-11.8,0H5.8
									c0.4,2.2,1,4.2,1.3,5.2c0.2,0.8,0.4,1.5,0.6,2C7.9,14,8,14.2,8,14.2c0.1,0.2,0.3,0.3,0.4,0.3c0.2,0.1,0.4,0.1,0.5,0.1l0,0
									C9.4,14.6,15.7,14.6,16.1,14.6z M17.7,14.6C17.7,14.6,17.7,14.6,17.7,14.6C17.7,14.6,17.7,14.6,17.7,14.6z"
                    />
                  </g>
                </g>
              </svg>
            </span>
            <span className="badge">{productsCount}</span>
          </Link>
        </div>
        <div className="shopping-cart-total">
          <span className="lighter-text">Total:&nbsp;</span>
          <span className="main-color-text">
            {Number(cart.totalProductsPrice.replace("zł", "")).toFixed(2) +
              "zł | " +
              totalPriceEu}
          </span>
        </div>
      </div>
      <ul className="shopping-cart-items">
        {cart?.products.map((product) => (
          <li className="clearfix" key={product.productId}>
            {!isEmpty(product.image) ? (
              <figure>
                <LazyLoadImage
                  alt={product.image.altText ? product.image.altText : ""}
                  src={product.image.sourceUrl} // use normal <img> attributes as props
                  effect="blur"
                  height="70"
                  width="70"
                />
              </figure>
            ) : !isEmpty(productImagePlaceholder) ? (
              <figure>
                <LazyLoadImage
                  alt="default"
                  height="70"
                  src={productImagePlaceholder}
                  width="70"
                  effect="blur"
                />
              </figure>
            ) : null}
            <span className="item-name">
              <Link className="spec-h" to={product.link}>
                {product.name}
              </Link>
            </span>
            <span
              className="item-price"
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                marginRight: "19px",
                color: "#640830",
                marginTop: "10px",
              }}
            >
              {product.totalPrice +
                " | " +
                Number(product.totalEuPrice).toFixed(2) +
                "€"}
            </span>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end",
                paddingRight: "1em",
                alignItems: "center",
              }}
            >
              <span className="item-quantity">Quantity: {product.qty}</span>
              {product.variation ? (
                <>
                  <br />
                  {product.variation.attributes.map((item, i) => {
                    return (
                      <>
                        {i < 1 ? (
                          <span className="item-quantity">{item.value}</span>
                        ) : (
                          <>
                            &nbsp;|&nbsp;
                            <span className="item-quantity">
                              <b>{item.value}</b>
                            </span>
                          </>
                        )}
                      </>
                    );
                  })}
                </>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
      <Link to="/cart/" className="button">
        Cart
      </Link>
      <CheckoutButton color={"white"} />
    </div>
  );
};

export default CartDropDown;
