import React from "react";
import Link from "gatsby-link";

const CheckoutButton = (props) => {
  let color = props.color;

  return (
    <Link className="checkout" style={{ color: `${color}` }} to={"/checkout/"}>
      <span className="btn btn-outline-dark">Checkout</span>
    </Link>
  );
};

export default CheckoutButton;
