import React from "react";
import Link from "gatsby-link";
/**
 * Internal dependencies.
 */
import Nav from "./nav";
import "./style.scss";
import defaultSiteLogoUrl from "../../images/logo.svg";
import EULogo from "../../images/logo_UE-EN.svg";


const Header = ({ data }) => {
  const {
    wp: {
      header: { siteLogoUrl },
    },
    headerMenuItems,
  } = data;
  const siteLogoURL = siteLogoUrl ? defaultSiteLogoUrl : defaultSiteLogoUrl;

  return (
    <>
    <div style={{width:'100%',fontSize:'12px',height:'45px',lineHeight:'45px',backgroundColor:'#F7ADB6',color:'#111111',textAlign:'center'}}>
    <img
      className="eu__logo"
      src={EULogo}
      style={{position:'absolute',height:'45px',left:'0px',top:'0px'}}
      width="auto"
      height="45px"
      alt="European Union"
    />
    <div id="promo-bar"><span role="img" aria-label="Free shipping! ">✨</span>&nbsp;<b>Free shipping</b> on orders over 200PLN&nbsp;<span role="img" aria-label=" Free shipping!">✨</span></div>
    </div>
    <header className="site-header-container container" style={{background:'#FCCDB7'}}>
      <div className="site-header">
        <div className="site-brand">
          <Link to="/">
	          <figure>
		          <img
			          className="site-brand__logo"
			          src={siteLogoURL}
			          width="128"
			          height="auto"
			          alt="header logo"
		          />
	          </figure>
          </Link>
          <br />
        </div>

        <Nav headerMenuItems={headerMenuItems} />
      </div>
    </header>
    </>
  );
};

/**
 *  Exporting Just the footer as well without static query for storybook,
 *  as static query does not work in storybook
 */
export { Header };
