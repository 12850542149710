import React from "react";
import Link from "gatsby-link";

const CheckoutButton = (props) => {
  let color = props.color;

  return (
    <Link
      className="checkout button"
      style={{ color: `${color}` }}
      to={"/checkout/"}
    >
      <span>Checkout</span>
    </Link>
  );
};

export default CheckoutButton;
