import React from "react";
import Link from "gatsby-link";

const CheckoutLink = (props) => {
  let color = props.color;

  return (
    <Link className="checkout" style={{ color: `${color}` }} to={"/checkout/"}>
      Checkout
    </Link>
  );
};

export default CheckoutLink;
