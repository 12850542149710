/**
 * External dependencies.
 */
import React from 'react';
import Link from 'gatsby-link';

/**
 * FacebookIcon Component.
 *
 * @return {jsx}
 */
const UserIcon = () => (
	<Link to="/my-account/" id="user-mobile" aria-label="My account" className="wishlist-menu">
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		 viewBox="0 0 18 18" style={{enableBackground:'new 0 0 18 18',width:'23px',height:'auto'}}>
		 <path d="M8.9,7.9c-2,0-3.6-1.6-3.6-3.6S7,0.8,8.9,0.8s3.6,1.6,3.6,3.6S10.9,7.9,8.9,7.9z M8.9,1.3c-1.7,0-3,1.3-3,3s1.3,3,3,3
		 	s3-1.3,3-3S10.6,1.3,8.9,1.3z"/>
		 <path d="M8.9,17.2c-1.9,0-3.8,0-5.1-0.1c-2.6-0.1-2.6-0.2-2.6-0.5c0-0.7,1.6-5.8,3.3-7.4c0.4-0.4,0.7-0.6,1.2-0.6
		 	c0.4,0,0.8,0.3,1.1,0.6c0.4,0.4,1,0.9,2.1,0.9c1.1,0,1.7-0.4,2.2-0.9c0.4-0.4,0.8-0.6,1.2-0.6c2.1,0,4.3,7.1,4.3,7.9
		 	C16.7,16.9,16.7,17.2,8.9,17.2z M1.9,16.5c1.6,0.3,12.7,0.3,14.3,0c-0.3-1.3-2.3-7.2-3.8-7.2c-0.3,0-0.5,0.2-0.9,0.4
		 	c-0.5,0.4-1.2,1.1-2.6,1.1c-1.4,0-2-0.6-2.6-1.1C6,9.5,5.8,9.3,5.6,9.3l0,0c-0.2,0-0.4,0.1-0.7,0.4C3.5,11.2,2,15.4,1.9,16.5z"/>
		 <path className="st0" d="M4.8,8.4"/>
	 	</svg>
	</Link>
);

export default UserIcon;
